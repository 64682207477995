import React from 'react'

const GradientContainer = ({ children }) => {
  return (
    <div className='h-100 md:min-h-screen p-0 md:p-12 bg-gradient-to-br from-dark to-darker'>
      { children }
    </div>
  )
}

export default GradientContainer