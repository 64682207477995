import React from 'react'

const Button = ({ type, children }) => {

  const typeClasses = type === 'primary' ? 'bg-secondary border-2 border-secondary' : 'bg-transparent border-2 border-white';
  const classes = `${typeClasses} text-white text-sm md:text-base font-bold uppercase px-6 py-2 rounded-lg hover:scale-110 transform transition duration-300 ease-in-out`;

  return (
    <button className={classes}>
      { children }
    </button>
  )
}

export default Button
