import React from 'react'
import { motion } from 'framer-motion'

const Card = ({ children, classes, x, y}) => {
 
  const backCardAnim = {
    hidden: {
      x: 0,
      y: 0,
    },
    show: {
      x: x,
      y: y,
      transition: {
        duration: 1,
        delay: 0.3,
        type: 'spring',
        bounce: 0.3,
      }
    }
  }

  return (
    <div className={`relative`}>
      <div className='relative z-50 p-4 text-black bg-white rounded-lg md:p-8'>
        { children }
      </div>
      <motion.div
        className={`hidden md:block rounded-lg z-0 absolute top-0 right-0 h-full w-full ${classes}`}
        initial='hidden'
        whileInView='show'
        variants={backCardAnim}
        viewport={{ once: true }}
      >
      </motion.div>
    </div>
  )
}

export default Card